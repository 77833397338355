import { DocumentFileDto } from './history.state';
import { FiltersKeyEnum } from '../../../shared/enums/filtersKey.enum';

export class GetDocumentHistory {
  static readonly type = '[History] Get document history';

  constructor(public uid: string) {}
}

export class RefreshDocumentHistory {
  static readonly type = '[History] Refresh document history';
}

export class CreateHistoryItem {
  static readonly type = '[History] Create Item';

  constructor(
    public documentTypeIds: number[],
    public allDocuments: boolean,
  ) {}
}

export class MakeStartedAO {
  static readonly type = '[History] Create Started AO';

  constructor(public uid: string) {}
}

export class PrintHistoryDoc {
  static readonly type = '[History] Print document';

  constructor(
    public historyId?: number,
    public openInTab = true,
  ) {}
}

export class OpenHistorySign {
  static readonly type = '[History] Open sign popup';

  constructor(
    public documentTypeIds: number[],
    public allDocuments: boolean,
  ) {}
}

export class SignHistoryDocs {
  static readonly type = '[History] Sign docs';

  constructor(public sign: string) {}
}

export class DeleteHistoryItem {
  static readonly type = '[History] Delete history item';

  constructor(public historyItemId: number) {}
}

export class UploadDocument {
  static readonly type = '[History] Upload document';

  constructor(public data: DocumentFileDto) {}
}

export class OpenUploadPopup {
  static readonly type = '[History] Open upload popup';

  constructor(public row: any) {}
}

export class HistorySendEmail {
  static readonly type = '[History] Send email';

  constructor(public allDocuments: boolean) {}
}

export class HistorySendSms {
  static readonly type = '[History] Send sms';

  constructor(
    public number: string,
    public documentTypeIds: number[],
    public allDocuments: boolean,
  ) {}
}

export class HistorySync {
  static readonly type = '[History] Sync documents';

  constructor(public documentIds: string[]) {}
}

export class ChangeCreatedAt {
  static readonly type = '[History] Change createdAt';

  constructor(
    public documentHistoryId: number,
    public newDate: string,
    public dateProperty: string,
  ) {}
}

export class GetEmployeeAttachmentsList {
  static readonly type = '[History] Get attachments list (admin)';

  constructor(
    public employeeId: string,
    public filters?: { order: string; dir: 'asc' | 'desc'; perPage?: number },
  ) {}
}

export class UploadEmployeeAttachment {
  static readonly type = '[History] Upload attachment (admin)';

  constructor(
    public title: string,
    public file: any,
  ) {}
}

export class AddAttachmentComment {
  static readonly type = '[History] Add comment to employee attachment';

  constructor(
    public attachmentId: string,
    public comment: string,
  ) {}
}

export class EditAttachment {
  static readonly type = '[History] Update attachment';

  constructor(
    public attachmentId: string,
    public body: { title: string },
  ) {}
}

export class DeleteAttachment {
  static readonly type = '[History] Delete attachment';

  constructor(public attachmentId: number | string) {}
}

export class DownloadAttachment {
  static readonly type = '[History] Download attachment';

  constructor(public attachmentIds: number | string | string[]) {}
}

export class DownloadAttachments {
  static readonly type = '[History] Download multiple attachments';

  constructor(
    public attachmentIds: string[],
    public uid?: string,
  ) {}
}

export class RefreshDocumentAttachments {
  static readonly type = '[History] Refresh employee attachments';

  constructor(public keyFilters: FiltersKeyEnum) {}
}

export class SyncAttachments {
  static readonly type = '[History] HhaExchangeSync attachments';

  constructor(public attachmentId: string) {}
}

export class SetHistoryPage {
  static readonly type = '[History] Set page';

  constructor(public page: number) {}
}

export class SetEmployeeAttachmentPage {
  static readonly type = '[History] Set attachment page';

  constructor(public page: number) {}
}

export class SetSpecialStatus {
  static readonly type = '[History] Set special status';

  constructor(
    public id: number,
    public statusId: number,
  ) {}
}

export class ResetDocumentFilters {
  static readonly type = '[History] Reset document filters';
}

export class SetPerPage {
  static readonly type = '[History] Set perPage';

  constructor(public perPage: number) {}
}

export class SetUID {
  static readonly type = '[History] SetUID';

  constructor(public uid: string) {}
}

export class SetUIDWithoutRequest {
  static readonly type = '[History] SetUIDWithoutRequest';

  constructor(public uid: string) {}
}

export class EnableDatatableLoading {
  static readonly type = '[History] EnableLoading';
}

export class SetMandatoryAttachmentEmployee {
  static readonly type = '[History] Set mandatory attachment';

  constructor(
    public uid: string,
    public idAttachments: number,
  ) {}
}
